import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "bookButton", "infoButton", "select" ]

  connect() {
    this.update()
  }

  update() {
    let selectedOption = this.selectTarget.options[this.selectTarget.selectedIndex]

    this.bookButtonTarget.setAttribute('href', selectedOption.dataset.bookNowUrl)
    this.infoButtonTarget.setAttribute('href', selectedOption.dataset.moreInformationUrl)
  }
}
