import { Controller } from "stimulus"
import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/mobilefriendly'

export default class extends Controller {
  connect() {
    this.picker = new Litepicker({
      element: this.element,
      // elementEnd: document.getElementById(this.element.dataset.elementEnd),
      startDate: this.element.value,
      // endDate: document.getElementById(this.element.dataset.elementEnd).value,
      // singleMode: false,
      // allowRepick: true,
      minDate: new Date(),
      lang: document.querySelector('body').dataset.userLocale,
      // maxDays: 21,
      // minDays: 2,
      // resetButton: () => {
      //   let btn = document.createElement('button');
      //   btn.setAttribute('class', "reset-button");
      //   let icon = document.createElement("i");
      //   icon.setAttribute('class', "fa fa-trash");
      //   btn.appendChild(icon);
      //
      //   return btn;
      // },
      // tooltipText: {
      //   one: 'night',
      //   other: 'nights'
      // },
      // tooltipNumber: (totalDays) => {
      //   return totalDays - 1;
      // },
      // numberOfMonths: 2,
      // numberOfColumns: 2,
      plugins: ['mobilefriendly']
    })

    this.picker.on('selected', (date1, date2) => {
      const year = date1.dateInstance.getUTCFullYear() - 2000
      const date = `${date1.dateInstance.getDate()}`.padStart(2, "0")
      const month = `${date1.dateInstance.getMonth() + 1}`.padStart(2, "0")
      document.querySelector(this.element.dataset.datePickerAlternateSelector).value = `${year}${month}${date}`
    });
  }
}
