import { Controller } from "stimulus"

export default class extends Controller {
  select() {
    const ratingInput = document.querySelector('#review_rating')
    ratingInput.value = this.element.dataset.value

    // .trigger('change').trigger('focusout');
    this.element.parentElement.querySelectorAll("i").forEach(element => {
      element.classList.remove('bi-star-fill')
      element.classList.add('bi-star')
    })
    this.element.parentElement.querySelectorAll("i").forEach((element, index) => {
      if (ratingInput.value > index) {
        element.classList.remove('bi-star')
        element.classList.add('bi-star-fill')
      }
    })
  }
}
