import { Controller } from "stimulus"
import { useResize } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "swiper", "progress", "tabs" ]

  connect() {
     useResize(this)

    this.slideTime = 10000
    this.updateTime = 150000
    this.progress = 0
    this.swiper = new Swiper(this.swiperTarget, {
      slidesPerView: 1,
      loop: true,
      allowTouchMove: false
    })
    this.resize()

    this.createSwitcherTimeout()
    this.incrementProgress()
    this.updateMetaVisibility()

    setTimeout(() => {
      this.element.closest('turbo-frame').src = '/lift-status'
    }, this.updateTime)
  }

  nextSlide() {
    this.swiper.slideNext()
    this.restartProgress()
    this.updateMetaVisibility()
  }

  skipSlide() {
    this.pause()
    this.swiper.slideNext()
    this.updateMetaVisibility()
  }

  switchSlide() {
    const activeSwiperName = this.swiperTarget.querySelector('.swiper-slide-active').dataset.name
    const activeTabsName = this.tabsTarget.querySelector('.nav-link.active').dataset.name
    if (activeSwiperName != activeTabsName) {
      this.pause()
      this.swiper.slideToLoop(parseInt(this.swiperTarget.querySelector(`.swiper-slide[data-name='${activeTabsName}']`).dataset.swiperSlideIndex))
      this.updateMetaVisibility()
    }
  }

  updateMetaVisibility() {
    this.element.querySelectorAll('.lift-status-slide-header').forEach(element => element.classList.add('d-none'))
    this.element.querySelectorAll('.lift-status-slide-footer').forEach(element => element.classList.add('d-none'))

    const name = this.element.querySelector(".swiper-slide-active").dataset.name
    this.element.querySelector(`.lift-status-slide-header[data-name='${name}']`).classList.remove('d-none')
    this.element.querySelector(`.lift-status-slide-footer[data-name='${name}']`).classList.remove('d-none')
    this.tabsTarget.querySelector(`.nav-link[data-name='${name}']`).click()
  }

  pause() {
    this.resetProgress()
    clearTimeout(this.switcherTimeout)

    this.element.querySelectorAll('.btn.pause').forEach(element => element.classList.add('d-none'))
    this.element.querySelectorAll('.btn.play').forEach(element => element.classList.remove('d-none'))
  }

  play() {
    this.restartProgress()
    this.createSwitcherTimeout()
    this.element.querySelectorAll('.btn.pause').forEach(element => element.classList.remove('d-none'))
    this.element.querySelectorAll('.btn.play').forEach(element => element.classList.add('d-none'))
  }

  resize() {
    const map = this.swiperTarget.querySelector('.map')
    const wrapper = map.closest('.scaleable-wrapper')
    const scale = wrapper.clientWidth / map.clientWidth

    this.swiperTarget.querySelectorAll('.map').forEach(element => element.style.transform = "translate(-50%, -50%) " + "scale(" + scale + ")")
    this.swiperTarget.querySelectorAll('.scaleable-wrapper').forEach(element => element.style.height = map.getBoundingClientRect().height + "px")
  }
  createSwitcherTimeout() {
    this.switcherTimeout = setTimeout(() => {
      this.nextSlide()
      this.createSwitcherTimeout()
    }, this.slideTime)
  }

  restartProgress() {
    this.resetProgress()
    this.incrementProgress()
  }

  resetProgress() {
    this.progress = 0
    this.progressTarget.style.width = `${this.progress}%`
    clearTimeout(this.progressTimeout)
  }

  incrementProgress() {
    this.progress += 5
    this.progressTarget.style.width = `${this.progress}%`
    this.progressTimeout = setTimeout(() => {
      this.incrementProgress()
    }, this.slideTime / 20)
  }
}
