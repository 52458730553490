import { Controller } from "stimulus"

// import SwiperCore, { Autoplay } from 'swiper'
// import Swiper bundle with all modules installed
// TODO: Change this to only required modules
import Swiper from 'swiper/bundle'
// import SwiperCore, { Autoplay } from 'swiper'
// SwiperCore.use([Autoplay])
window.Swiper = Swiper

export default class extends Controller {
  connect() {
    const swiper = new Swiper(this.element, {
      //slidesPerView: 'auto',
      slidesPerView: 2,
      loop: true,
      // loopedSlides: 22,
      // loopAdditionalSlides: 10,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      breakpoints: {
        640: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      }
    });
  }
}
