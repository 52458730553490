/* eslint no-console:0 */

import I18n from 'i18n-js/index.js.erb'
import * as Turbo from "@hotwired/turbo"
import Toast from "utils/toast"

window.Turbo = Turbo
window.I18n = I18n
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle")
import "controllers"
import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'

// Set season - Either previously selected or default
const season = localStorage['hanazononiseko.season'] || 'summer';
var htmlElement = document.querySelector('html');
htmlElement.setAttribute('data-season', season);

document.addEventListener("turbo:load", function() {
  // Register VirtualPageview to GTM
  window.dataLayer = window.dataLayer || []
  dataLayer.push({ 'event': 'VirtualPageview' })

  // Register PageRead to GTM
  setTimeout(() => {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({ 'event': 'PageRead' })
  }, 20000)

  I18n.locale = document.querySelector('body').dataset.locale

  // Most most read widget to proper location - can't do this serverside due to caching
  var mostReadPosts = document.querySelector('[data-most-read-posts]')
  if (mostReadPosts) {
    document.querySelector('[data-post-list]').insertBefore(mostReadPosts, document.querySelector('[data-post-list] div:nth-child(6)'))
  }

  // Main Menu Pane (Tab) Navigation
  const triggerTabList = [].slice.call(document.querySelectorAll('.main-menu.nav button'))
  triggerTabList.forEach(function (triggerEl) {
    const panel = document.getElementById(triggerEl.dataset.target)
    // Close offCanvas onload (incase user has navigated with Turbo
    panel.classList.remove("active")
    triggerEl.classList.remove('hover')

    triggerEl.addEventListener('mouseover', function (event) {
      panel.classList.add("active")
      triggerEl.classList.add('hover')
      triggerEl.addEventListener('mouseout', function (event) {
        if(event.relatedTarget != panel){
          panel.classList.remove("active")
          triggerEl.classList.remove('hover')
        }
        panel.addEventListener('mouseout', function(event){
          if(!panel.contains(event.relatedTarget)){
            panel.classList.remove("active")
            triggerEl.classList.remove('hover')
          }
        })
      })
    })
  })

  const listItems = document.querySelectorAll('.main-menu-list a');

  listItems.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.target.click()
    })

    let startX, startY;
    item.addEventListener('touchstart', (event) => {
      startX = event.changedTouches[0].clientX;
      startY = event.changedTouches[0].clientY;
    })
    item.addEventListener('touchend', (event) => {
      let endX = event.changedTouches[0].clientX;
      let endY = event.changedTouches[0].clientY;
      let distanceX = endX - startX;
      let distanceY = endY - startY;
      if (Math.abs(distanceX) < 10 && Math.abs(distanceY) < 10) {
        event.target.click()
      }
    })
  });	


  const offCanvasButton = document.querySelector('.menu-switch button')
  const offCanvas = document.querySelector('.off-canvas')
  const offCanvasClose = document.querySelector('.off-canvas .close-button')
  if (offCanvasButton) {
    offCanvasButton.addEventListener('click', () => {
      offCanvas.classList.remove('is-close');
      offCanvas.classList.add('is-open');
    })
    offCanvasClose.addEventListener('click', () => {
      offCanvas.classList.add('is-close');
      offCanvas.classList.remove('is-open');
    })

    // Close offCanvas onload (incase user has navigated with Turbo
    offCanvas.classList.add('is-close');
    offCanvas.classList.remove('is-open');
  }

  // Close popovers on outside click
  document.querySelector('body').addEventListener('click', function(e) {
    var in_popover = e.target.closest(".popover");

    if (!in_popover) {
      var popovers = document.querySelectorAll('.popover.show');

      if (popovers[0]) {
        var triggler_selector = `[aria-describedby=${popovers[0].id}]`;

        if (!e.target.closest(triggler_selector)) {
          let the_trigger = document.querySelector(triggler_selector);
          if (the_trigger) {
            bootstrap.Popover.getInstance(the_trigger).hide();
          }
        }
      }
    }
  });

  // Initialize popovers
  var popoverTriggerList = [].slice.call(document.querySelectorAll('.js-popover-trigger'))
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, { sanitize: false })
  })

  // Remove any open popovers
  var popovers = [].slice.call(document.querySelectorAll('.popover')).map(function (popover) {
    popover.remove()
  })
});
